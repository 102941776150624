.pulsating-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.pulsating-circle:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 45px;
    background-color: #ffffff;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #007CED;
    border-radius: 100px;
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); */
    /* animation: pulse-dot 1.30s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite; */
}

@keyframes pulse-ring {
    0% {
        transform: scale(0.33);
    }

    100% {
        opacity: 0;
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(0.6);
    }

    50% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(0.6);
    }
}

/* abh portfolio */