/****/
/* Containers */
/****/

.Sidebar-Container, .Details-Container {
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.sidebarLeft {
    transform: translateX(-220px);
}

.sidebarRight {
    transform: translateX(0px);
}

.detailsLeft {
    transform: translateX(0px);
}

.detailsRight {
    transform: translateX(340px);
}

/****/
/* Buttons */
/****/

.Details-Button, .Sidebar-Button {
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.sBtnLeft {
    transform: translateX(0px);
}

.sBtnRight {
    transform: translateX(150px);
}

.dBtnLeft {
    transform: translateX(-270px);
}

.dBtnRight {
    transform: translateX(0px);
}