/* preloader */
.preloader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pre {
    height: 70px;
    width: 70px;
    stroke: 5px solid black;
    opacity: 0.4;
}

.pre path:nth-child(1) {
    stroke-dasharray: 1910.60205078125;
    stroke-dashoffset: 1910.60205078125;
    animation: fill-loader 2s ease alternate-reverse infinite 0s;
}

.pre path:nth-child(2) {
    stroke-dasharray: 1074.5838623046875;
    stroke-dashoffset: 0;
    animation: empty-loader 2s ease alternate-reverse infinite 0s;
}

@keyframes empty-loader {
    to {
        stroke-dashoffset: 1074.5838623046875;
    }
}

@keyframes fill-loader {
    to {
        stroke-dashoffset: 0;
    }
}