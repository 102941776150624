.card-resume-inner {
    box-shadow: 0px 0px 12px rgb(0 0 0 /0.4);
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.card-resume-line, .card-resume {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.card-resume:hover {
    border-color: #007CED !important;
}

.card-resume:hover .card-resume-line {
    border-color: #007CED !important;
    width: 100% !important;
}

.card-resume:hover .card-resume-inner {
    opacity: 0.8;
}