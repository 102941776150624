.App {
  text-align: center;
  background-color: rgb(29, 42, 42);
  height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}