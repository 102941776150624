/****/
/* Hamburger Menu Animation */
/****/

#hamburger-icon {
    position: relative;
    width: 15px;
    height: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}

#hamburger-icon span {
    border-radius: 100px;
    height: 2px;
    width: 100%;
    opacity: 1;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
}

#hamburger-icon.open {
    justify-content: center !important;
}

#hamburger-icon.open span:nth-child(1) {
    position: absolute;
    transform: rotate(135deg);
}

#hamburger-icon.open span:nth-child(2) {
    opacity: 0;
    width: 0%;
    /* margin-left: -300px; */
}

#hamburger-icon.open span:nth-child(3) {
    position: absolute;
    transform: rotate(-135deg);
}