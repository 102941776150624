.card-contact-inner {
    box-shadow: 0px 0px 12px rgb(0 0 0 /0.4);
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
    border-bottom: 2px solid #444444;
}

.card-contact-inner:hover {
    /* scale: 1.01; */
    opacity: 0.8;
    border-color: #007CED !important;
}