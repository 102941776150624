.banner-bg {
    z-index: 10;
}

.banner-bg::before {
    position: absolute;
    content: "";

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    z-index: -2;

    background-image: url("../../assets/background/bg_landscape.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.banner-bg::after {
    position: absolute;
    content: "";

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    z-index: -1;

    background-color: #1B1B1B;
    opacity: 0.9;
}

.banner-internal {
    z-index: 10;
}

.banner-internal::before {
    position: absolute;
    content: "";

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    z-index: -2;

    background-image: url("../../assets/background/bg_full.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.banner-internal::after {
    position: absolute;
    content: "";

    height: 100%;
    width: 100%;

    top: 0;
    left: 0;
    z-index: -1;

    background-color: rgba(0, 0, 0, 0.28);
    /* background-color: #141414;
    opacity: 0.2; */
}

/* abh portfolio */