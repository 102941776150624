.Sidebar-Menu {
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.menuLeft {
    width: 60px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
}

.menuRight {
    width: 200px;
}