.animation-circles {
    height: 0px;
    width: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    z-index: -1;

    animation-name: cardBasic1;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards
}

.animation-circles div {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 2px solid white;
    border-radius: 9999px;

    height: 100%;
    width: 100%;
    padding: 15%;
}

.card-inner:hover .animation-circles {
    animation-name: cardBasic;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

.card-inner {
    box-shadow: 0px 0px 12px rgb(0 0 0 /0.4);
}

.card-inner:hover {
    scale: 1.01;
}



@keyframes cardBasic {
    0% {
        height: 0px;
        width: 0px;
        opacity: 0;
    }

    100% {
        height: 160px;
        width: 160px;
        opacity: 0.08;
    }
}

@keyframes cardBasic1 {
    0% {
        height: 160px;
        width: 160px;
        opacity: 0.08;
    }

    100% {
        height: 0px;
        width: 0px;
        opacity: 0;
    }
}

/*  */

.points-list {
    list-style: none;
}

.points-list li:before {
    content: '✓';
    font-size: 12px;
    font-weight: bold;
}