.percent {
    /* animation: increase-gradually 1.30s ease-in-out -1.5s infinite; */
    animation-name: fill-gradually;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
}

@keyframes fill-gradually {
    0% {
        stroke-dashoffset: 100;
    }

}