/* .empty {
    width: 0;
} */

.fill {
    animation-name: increase-gradually;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
}

@keyframes increase-gradually {
    0% {
        width: 0;
    }

}